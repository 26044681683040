body.light-mode {
	background-color: #fff;
	color: #333;
	transition: background-color 0.3s ease;
}
body.dark-mode {
	background-color: #121212;
	color: #999;
}

.dark-mode input {
	border-color: #555;
	background-color: #333;
	color: #fff;
}

.dark-mode input:focus {
	background-color: #333;
	color: #fff;
}

.dark-mode input::placeholder {
	color: #fff;
}

.dark-mode .card {
	background-color: #333;
	color: #f8f9fa;
}

.dark-mode .modal-content {
	background-color: #333;
	color: #f8f9fa;
}

.dark-mode .modal-header {
	border-bottom: 1px solid #555;
}
